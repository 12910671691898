.card {
  background-color: rgb(17, 24, 39);
  border: 2px solid rgb(100, 252, 241);
  border-radius: 12px;
  padding: 5%;
  margin: 2%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%; /* Set cards to 70% of the screen size */

  @media screen and (max-width: 480px) {
    width: 90%; /* Adjust width for smaller screens */
    margin: 5% 0; /* Increase margin for better spacing */
  }
}
h3 {
  font-size: 27px;
  font-weight: 700;
  font-family:'Poppins', sans-serif;
  color:aqua;
}

p {
  font-family:'Courier New', Courier, monospace;
}
.card:hover {
  transform: scale(1.05);
}

.card-content {
  text-align: center;
  margin-top: 20px;
}

.card-image {
  width: 90%;
  height: 200px; /* Set a fixed height in pixels for the images */
  object-fit: cover; /* Ensure the images cover the entire container */
  border-radius: 8px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .card {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2%; /* Adjust padding for better spacing */
  }

  .card-image {
    width: 40%;
    max-height: 80%; /* Ensure the images maintain proportion */
    margin-bottom: 0;
  }

  .card-content {
    flex: 1;
    text-align: center;
    margin-top: 0;
    margin-left: 0;
  }
}
