/* app.css */
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.highlighted-text {
  font-family: "Verdana", sans-serif;
  font-weight: 800;
}
.body-h2 {
  font-size: 2.7rem;
  color: aliceblue;
  font-weight: 700;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.hero-page {
  background-image: url("assets/circuit.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #66fcf1;
  text-align: center;
  font-family: Castellar;
  padding: 40px;
  font-size: 2.5em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 3em;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.4em;
    background-size: cover;
  }

  #home .header {
    @media screen and (max-width: 480px) {
      h1 {
        font-size: 1.2em;
      }

      .highlighted-text {
        font-size: 1.2em;
      }
      .extra-info {
        font-size: 0.5em;
      }
    }
  }

  h1 {
    font-size: 1.5em;
    margin: 0px;
    padding: 0px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    /* text-decoration: wavy; */
    /* animation: moveText 2s ease-in-out infinite; */
  }
  .highlighted-text1 {
    font-size: 2em;
  }

  p {
    font-size: 0.6em;
    margin: 0;
    padding: 0px;
    font-family: poppins, sans-serif;
  }
  .edition {
    font-size: 0.7em;
    font-weight: 550;
  }


  .btn {
    padding: 10px 20px;
    border: 2px solid rgb(100, 252, 241);
    background-color: black;
    color: white;
    border-radius: 10px;
    margin: 20px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .btn:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  }

  .btn a {
    text-decoration: none;
    font-weight: 700;
    color: aqua;
  }
}

h2 {
  color: aliceblue;
  font-size: 2.5em;
}

main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  align-items: center;
}

footer {
  background-color: rgba(51, 51, 51, 0.8);
  color: white;
  text-align: center;
}

.footer-section {
  display: inline-block;
  margin: 20px;
}

.contact-info {
  margin-top: 20px;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@keyframes moveText {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(5px);
  }
}

@media screen and (max-width: 480px) {
  .hero-page {
    padding: 20px;
  }

  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }

  .btn {
    font-size: 1.2rem;
  }
  .info-img {
    height: 20%;
    width: auto;
  }
  body {
    overflow-x: hidden;
  }
}
.info-img {
  height: 30%;
  width: auto;
}
.prakalpa-hero-img {
  margin-top: 5%;
  height: 12%;
  width: 1050px;
}
@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .prakalpa-hero-img {
    margin-top: 5%;
    height: 14%;
    width: 635px;
  }
}
@media screen and (max-width: 480px) {
  body {
    overflow-x: hidden;
  }
  .prakalpa-hero-img {
    margin-top: 5%;
    height: 10%;
    width: 350px;
  }
}

.left-corner-image {
  margin-top: 5%;
  height: auto;
  width: auto;
}
@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .left-corner-image {
    margin-top: 5%;
    height: auto;
    width: auto;
  }
}
@media screen and (max-width: 480px) {
  body {
    overflow-x: hidden;
  }
  .left-corner-image {
    margin-top: 5%;
    height: auto;
    width: auto;
  }
}




